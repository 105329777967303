.form-check-input {
    position: unset;
    margin-top: unset;
    margin-left: unset;
}

.center-image-span {
    height: unset;
}

.ladda-button {
    & > .ladda-label, & > .ladda-spinner {
        z-index: unset;
    }
}

.location-save {
    margin-top: 32px;
}

.status-checks-column {
    min-width: 276px;
}

td.heading-cell {
    border-top: 0px;
}

.nav-link.active {
    cursor: pointer;
}

.header-profile-image {
    border-radius: 50%;
}

.comment-modal {
    max-width: 80% !important;
}

.container-fluid {
    height: 100%;
}

.refresh-button {
    min-width: 95px;
}

.wiw-root-container-center {
    margin: unset;
    width: unset;
}
