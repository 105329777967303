.workflow-form {
    .form-group {
        margin-bottom: 0.4rem;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.test {
    display: block;
}
