.form-split-pane-collapsed {
    height: calc(100vh - 250px);
    position: static !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
    .Pane {
        background: #f5f5f5;

        .pane-content {
            min-width: 355px;
        }
    }
}

.form-split-pane {
    height: calc(100vh - 410px);
    position: static !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
    .Pane {
        background: #f5f5f5;

        .pane-content {
            min-width: 355px;
        }
    }
}

.form-header {
    padding: 8px 10px;
    background: #e4e4e4;
}

.form-scroll-container-collapsed {
    height: calc(100vh - 285px);
    overflow-y: scroll;
    scrollbar-width: thin; // firefox specific
    padding: 0 0 5px 0;
    &::-webkit-scrollbar {
        width: 8px;
        background: #e4e6eb;
    }

    &::-webkit-scrollbar-thumb {
        background: #cdcdcd;

        &:hover {
            background: #9b9b9b;
        }

        &:active {
            background: #7a7a7a;
        }
    }
}

.form-scroll-container {
    height: calc(100vh - 445px);
    overflow-y: scroll;
    scrollbar-width: thin; // firefox specific
    padding: 0 0 5px 0;
    &::-webkit-scrollbar {
        width: 8px;
        background: #e4e6eb;
    }

    &::-webkit-scrollbar-thumb {
        background: #cdcdcd;

        &:hover {
            background: #9b9b9b;
        }

        &:active {
            background: #7a7a7a;
        }
    }
}

.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.vertical {
    width: 3px;
    margin: 0 -2px;
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-right: 1px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover {
    border-color: transparent;
}

.rotate {
    transform: translate(-345px, 345px) rotate(90deg);
    transform-origin: center;
    width: 730px;
    height: 40px;
}

.enrollment-person-header-text {
    color: #ff7300;
}

.enrollment-person-header-rotate {
    color: #ff7300;
    background: linear-gradient(120deg, #323232 85%, #ff7300 0);
}

.enrollment-credential-header-text {
    color: #43bb85;
}

.enrollment-credential-header-rotate {
    color: #43bb85;
    background: linear-gradient(120deg, #323232 85%, #43bb85 0);
}

.enrollment-capture-header-text {
    color: #0be;
}

.enrollment-capture-header-rotate {
    color: #0be;
    background: linear-gradient(120deg, #323232 85%, #0be 0);
}

.enrollment-secondary-text-color {
    color: white;
}

.enrollment-finalize-header {
    position: fixed;
    bottom: 50px;
    width: calc(100% - 286px);
    z-index: 1;
    display: flow-root;
    color: #635faa;
    background: linear-gradient(120deg, #323232 85%, #635faa 0);
}

.enrollment-finalize-button {
    color: #fff;
    align-content: center;
}

.enrollment-person-header-finalization {
    color: #ff7300;
    background: #323232;
}

.enrollment-credential-header-finalization {
    color: #43bb85;
    background: #323232;
}

.enrollment-capture-header-finalization {
    color: #0be;
    background: #323232;
}

.text-color-red {
    color: red;
}

.text-color-green {
    color: green;
}

.text-color-warning {
    color: orange;
}
