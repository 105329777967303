// https://github.com/Holger-Will/code-39-font
@font-face {
    font-family: 'code39';
    src: url('./img/code39_L.woff') format('woff'), url('./img/code39_L.ttf') format('truetype');
}

.barcode {
    font-family: 'code39';
}

.app-home-title {
    font-family: 'Kavivanar';
}
