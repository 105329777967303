@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-step-progress-bar/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~text-security/text-security.css';

@import '~valid-ui-components/dist/index.css';
@import '~ui-address-form-widget/dist/index.css';
@import '~ui-photo-capture-widget/dist/index.css';
@import '~ui-history-widget/dist/index.css';
@import '~ui-signature-capture-widget/dist/index.css';
@import '~ui-document-capture-widget/dist/index.css';
@import '~ui-datagrid-widget/dist/index.css';
@import '~ui-search-widget/dist/index.css';
@import '~ui-comment-widget/dist/index.css';
@import '~ui-doc-management-tools/dist/index.css';
@import '~ui-work-item-widget/dist/index.css';

@import '~wf-app-review/dist/index.css';
@import '~wf-all-app-data/dist/index.css';
@import '~wf-user-profile/dist/index.css';
@import '~wf-vic-settings/dist/index.css';

@import '~@fortawesome/fontawesome-pro/css/all.min.css';

@import 'app-event-history-modal';
@import 'card-management';
@import 'demoStyles';
@import 'react-split-pane';
@import 'overrides';
@import 'form-field';
@import 'multistep-form';
@import 'loading-spinner';
@import 'fonts';
@import 'config-editor';
@import 'password-fields';
@import 'wiw-buttons';
@import 'capture-only';

.expandable-image-wrapper {
    display: flex;
}

/* This overrides styles from the Header component.  Added this hacky workaround to get ready for a demo */
.app-header.navbar .navbar-brand {
    background-size: 110px 40px;
    background-position: 0 50%;
}

.biom-main-failure-container .biom-fail-reasons-container {
    margin-top: 2rem;
}