
.btn-4c6ef5 {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-decoration: none;
    color: #fff;
    background-color: #4c6ef5;
    border-color: #4462db;
    cursor: pointer;
    margin-bottom: 1rem;
}
.btn-4c6ef5:hover {
    color: #fff;
    background-color: #4462db;
    border-color: #4c6ef5;
}

.btn-B000B5 {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-decoration: none;
    color: #fff;
    background-color: #B000B5;
    border-color: #97029c;
    cursor: pointer;
    margin-bottom: 1rem;
}

.btn-B000B5:hover {
    color: #fff;
    background-color: #97029c;
    border-color: #B000B5;
}

.btn-43bb85 {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-decoration: none;
    color: #fff;
    background-color: #43bb85;
    border-color: #3aa374;
    cursor: pointer;
    margin-bottom: 1rem;
}

.btn-43bb85:hover {
    color: #fff;
    background-color: #3aa374;
    border-color: #43bb85;
}
