.spinner-container {
    position: fixed;
    top: calc(50% - 80px);
    left: calc(50% + 50px);
    font-size: 100px;

    i {
        color: #635faa;
    }

    .spinner-text {
        font-size: 16px;
        text-align: center;
        position: relative;
        top: -84px;
    }
}
