.multistep-enrollment-workflow {
    .form-buttons {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}
.start-capture-button {
    margin-left: 1rem;
}

.enrollment-steps-bar-container {
    width: 90%;
    margin: 20px auto;
    height: 40px;

    .icon-label {
        position: absolute;
        text-align: center;
        top: 40px;
        font-size: 10px;
    }

    .indicator-icon {
        transform: translate(16px, -16px);
    }

    .success {
        color: #43bb85;
    }

    .warning {
        color: #FD7E14;
    }

    .complete {
        color: #43bb85;
    }

    .error {
        color: #bb5343;
    }
}

.data-verify-ssn-display {
    display: flex;
    justify-content: center;
}

.data-verify-content-section {
    margin-top: 1rem;
    margin-bottom: 1rem;
    min-height: 150px;
}

.status-check-clickable {
    cursor: pointer;
}

.status-checks-text-color {
    margin-left: 0.75rem;
    color: #635faa;
}
.status-icon {
    width: 1.75rem;
}

.status-check-group {
    margin-left: 4rem;
    margin-bottom: 1rem;
    font-size: 1.25em;
}

.sticky-status-checks .status-check-group {
    margin-left: inherit;
    font-size: inherit;
}

.status-check-validation-fail {
    cursor: pointer;
    color: #635faa;
}

.step-icon {
    cursor: pointer;
}

.step-icon.disabled {
    cursor: not-allowed;
}
