.modal-footer .wizard-controls {
    margin: 0 auto 0 0;
    button {
        margin: 0.25rem;
    }
}
.wizard-launcher button {
    margin-left: 1rem;
}

.settings {
    .autostart {
        margin: 0 auto 0 0;
    }

    .dropbox {
        display: flex;
        flex-direction: column;
        background-color: #e6e6e6;
        min-height: 30px;
        .draggable-item {
            padding: 10px;
            margin: 10px;
            color: white;
            background-color: #635faa;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .draggable-item.dragging {
            background-color: #43bb85;
        }
    }
    .dropbox.dragging {
        background-color: paleturquoise;
    }
    .settings-body {
        display: flex;
        justify-content: center;
    }
    .settings-list {
        margin: 0 10px;
        width: 100%;
        .description .icon {
            display: inline-block;
        }
        .description .text {
            display: inline-block;
        }
        .configurator {
            flex-basis: 100%;
            .input {
                color: initial;
            }
        }
        .config-divider {
            background-color: white;
            flex-basis: 100%;
            margin: 5px 0 10px;
        }
    }
}
