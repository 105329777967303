.config-control-buttons {
    width: 175px;
}

.config-secret-button {
    display: inline-block;
}

.config-proof-doc-type {
    height: 300px;
}
