.demo-widget {
    border: 1px dashed black;
    background-color: lightgray;
    text-align: center;
}

.warning-symbol-color {
    font-size: 1em;
    color: #FD7E14;
}

.demo-widget::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; // Adjusts for spacing
}

.demo-text-centered {
    display: inline-block;
    vertical-align: middle;
    // width: 300px;
}

.photo-widget-demo {
    height: 250px;
    width: 200px;
}

.search-widget-container {
    margin: auto;
    width: 50%;
}

.sw-modifier-container {
    width: 300px;
    position: absolute;
    z-index: 200;
}

.history-widget-container {
    margin: auto;
    width: 50%;
}

.hstw-container-compact {
    &:nth-child(1) {
        .hstw-meta-container-compact {
            background: #635faa;
        }
    }

    &:nth-child(2) {
        .hstw-meta-container-compact {
            background: #0be;
        }
    }

    &:nth-child(3) {
        .hstw-meta-container-compact {
            background: #43bb85;
        }
    }
}

.add-margin-top {
    margin-top: 1em;
}

.add-margin-bottom {
    margin-bottom: 1em;
}

.add-margin-left {
    margin-left: 1em;
}

.add-margin {
    margin: 1em;
}

.app-review-ctrl-container {
    display: inline-block;
}

.app-review-status-dropdown {
    width: 12rem;
}

.rock-salt-font {
    font-family: 'Rock Salt', regular;
}

.home-title {
    color: #43bb85;
    font-size: 1.25em;
    font-style: italic;
}

.vt-landing-page-wrapper {
    height: 730px;
}

.vt-landing-page-tiles {
    align-self: center;
}

.landing-page-cards {
    width: 200px;
    opacity: 0.85;
}

.module-select-option {
    font-size: 1rem;
    padding: 0.5rem 1rem;

    &:hover {
        background: #efefef;
        cursor: pointer;
    }

    &.selected {
        background: #0be;
        font-weight: bold;
        color: white;
    }
}

.read-only-field {
    padding-top: 7px;
}

.display-password {
    cursor: pointer;
    margin-top: 0.5rem;
    height: 1rem;
}

.display-password:active {
    color: red;
}

.divider-enrollment {
    margin-top: 1px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgb(247, 247, 247);
}

.agency-issued {
    font-size: 2em;

    .agency-issued-span {
        color: #43bb85;
    }
}

.home-location-container {
    min-width: 298px;
    display: flex;
}

.home-location-text {
    font-size: 1.3rem;
    color: #635faa;
    font-weight: 500;
}

.flocation-text {
    font-weight: bold;
}

.brand-minimized .navbar-brand {
    padding: 0 !important;
    background-image: url('../../public/img/favicon.ico') !important;
}

.home-card-container {
    min-width: 298px;
    display: flex;
}

.data-verify-span {
    width: 32em;

    .data-verify-inputs {
        width: 80%;
    }
}

.data-verify-photo {
    width: 300px;
}

.btn-license-issue {
    background-color: #af00b5;
    color: white;
}

.btn-license-issue:hover {
    background-color: darken(#af00b5, 10%);
    color: white;
}

.btn-card-management {
    background-color: #43ba84;
    color: white;
}

.btn-card-management:hover {
    background-color: darken(#43ba84, 10%);
    color: white;
}

.location-text {
    font-weight: bold;
}

.wit-widget-wrapper-lic-issue {
    display: inline-block;
}

.cancel-btn-wapper-lic-issue {
    display: inline-block;
}

.license-issue-save-btn {
    background: #FF7300;
    color: white;
}

.license-issue-save-btn:hover {
    background-color: darken(#FF7300, 10%);
    color: white;
}

.capture-only-save-btn {
    background: #FF7300;
    color: white;
}

.capture-only-save-btn:hover {
    background-color: darken(#FF7300, 10%);
    color: white;
}

.round-button {
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.demo-footer {
    color: #ffce5cd6;
}

.demo-footer:hover {
    color: #009AC7;
    text-decoration: none;
}
