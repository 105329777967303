.card-management-image-container {
    display: flex;
    justify-content: center;
    text-align: center;

    .image-bounds {
        height: 200px;
        width: 200px;
    }

    .compare-image-bounds {
        height: 360px;
        width: 360px;
    }
}

.vt-card-overlay {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(99, 95, 170, 0.4);
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 10px;
}

.vt-photo-overlay {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(0, 187, 238, 0.4);
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 10px;
}

.vt-signature-overlay {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(67, 187, 133, 0.4);
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 10px;
}

.overlay-icon-bar {
    display: flex;

    i {
        margin: 0 10px 0 0;
        z-index: 10;
    }
}

// .vt-hstw-expandable-modal-body {
//     display: flex;

.vt-hstw-expandable-modal-image {
    max-height: 600px;
    background: #efefef;
}
.card-image-modal-container {
    width: 45%;
    display: inline-block;
}
// }
